import React, {Suspense, lazy, useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Skeleton } from "@mui/material";
import PrivateRoutes from "../App/Utils/PrivateRoute/index";
// import ReactPixel from 'react-facebook-pixel';

export default function App(){
  const LandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));
  const ReceiptPage = lazy(() => import("./Pages/ReceiptPage/ReceiptPage"));
  const NotFound = lazy(() => import("./Pages/404/Notfound404"));
  const ExpiredPage = lazy(() => import("./Pages/ExpiredPage/ExpiredPage"));
  const BonusPage = lazy(() => import("./Pages/BonusPage/BonusPage"));
  const Password = lazy(() => import("./Pages/PasswordPage/Password"));
  const PasswordA = lazy(() => import("./Pages/PasswordPage/PasswordA"));
  const BonusBedahBuku = lazy(() => import("./Pages/Recording/Recording"));
  const LandingPageB = lazy(() => import("./Pages/LandingPage/LandingPageB"));
  const LandingFree = lazy(() => import("./Pages/LandingPage/LandingFree"));
  const LandingTq = lazy(() => import("./Pages/LandingPage/LandingFreeTq"));
  const SalesPageTq = lazy(() => import("./Pages/LandingPage/LandingTq"));
  // const LandingPagePromo17 = lazy(() => import("./Pages/LandingPage/LandingPagePromo"));
  useEffect(() => {
    // ReactPixel.init('851045128398165');
    // ReactPixel.init('2969238869766982');
    // ReactPixel.init('481381929455161');
    // ReactPixel.pageView();
  })
  return (
    <>
      <Router>
        <Suspense fallback={<Skeleton variant="rectangular" width={"100%"} height={"100%"} />}>
          <Routes>
            <Route path="/" element={<LandingPage/>} exact/>
            <Route path="/nonem" element={<LandingPageB/>}/>
            <Route path="/nomor-resi" element={<ReceiptPage/>} exact/>
            <Route path="*" element={<NotFound/>}/>
            <Route path="/expired-offer" element={<ExpiredPage/>} exact/>
            <Route path="/free-ebook" element={<LandingFree/>} exact/>
            <Route path="/tq-free-ebook" element={<LandingTq/>} exact/>
            <Route path="/tq-logikacinta" element={<SalesPageTq/>} exact/>

            <Route element={<PrivateRoutes/>}>
              <Route path="/bonus-alpha-male" element={<BonusPage/>} exact/>
              <Route path="/bonus-bedah-buku-page" element={<BonusBedahBuku/>} exact/>
            </Route>
            <Route path="/bonus" element={<Password/>} exact/>
            <Route path="/bonus-bedah-buku" element={<PasswordA/>} exact/>
          </Routes>
        </Suspense>
      </Router>
    </>
  );
;}