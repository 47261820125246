
export const getToken = () =>{
    //get data token from local storage
    return sessionStorage.getItem("access_token") || null;
}

export const setUserSession = (token, user) =>{
    //set session user after success login
    sessionStorage.setItem("access_token", token);
    sessionStorage.setItem("user", user);
}

export const removeUserSession = () =>{
    //remove session user success logout from app
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user");
}